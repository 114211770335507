@import 'animated.css';
@import 'spinner.css';
@import 'fonts.css';
@import '../../node_modules/react-image-gallery/styles/css/image-gallery.css';
@import 'header.css';
@import 'content.css';
@import 'footer.css';

html,
body,
#__next,
#home {
  margin: 0;
  padding: 0;
  height: 100%;
}

@media screen and (max-width: 980px) {
  html {
    font-size: 18px;
  }
}

@media screen and (min-width: 981px) {
  html {
    font-size: 20px;
  }
}
