.spinner-wrapper{position:fixed;top:0;left:0;right:0;bottom:0;background-color:#FFF;z-index:999999}.spinner-container{width:100px;height:200px;left:50%;top:50%;margin-left:-50px;margin-top:-50px;position:absolute}.preload{display:inline-block;font-size:0px;padding:0}.preload span{vertical-align:middle;border-radius:100%;background:#000000;display:inline-block;width:10px;height:10px;margin:3px 2px;-webkit-animation:preload .8s linear infinite alternate;animation:preload .8s linear infinite alternate}.preload span:nth-child(1){-webkit-animation-delay:-.8;animation-delay:-.8s}.preload span:nth-child(2){-webkit-animation-delay:-.53333s;animation-delay:-.53333s}.preload span:nth-child(3){-webkit-animation-delay:-.26666s;animation-delay:-.26666s}@keyframes preload{from{transform:scale(0,0)}to{transform:scale(1,1)}}@-webkit-keyframes preload{from{-webkit-transform:scale(0,0)}to{-webkit-transform:scale(1,1)}}

.spinner-wrapper {
  animation-duration: 750ms;
}

.spinner-image {
  border-radius: 0px;
  width: 100px;
  height: auto;
}
