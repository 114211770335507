.header-bg {
  width: 100%;
  height: 100vh;

  background-size: cover;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url('../assets/hallo.jpg');
  background-attachment: fixed;

  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 60%;
  height: 50%;

  color: white;
}

.header-content div {
  margin: 0.1em 0;
}

#title-text {
  font-size: 4em;
  font-family: 'Solano-Gothic-Bold-Cap';
}

#subtitle-text {
  font-size: 3.5em;
  font-family: 'Solano-Gothic-Bold';
}
